import React from 'react';
import Helmet from 'react-helmet';
import { Segment } from '../components/segment/Segment';

export default () => (
  <>
    <Helmet title="about birkir.dev" />

    <Segment>
      <h1>Noop</h1>
    </Segment>
  </>
);
